@import "../../sass/colors";

.MailBox {
  width: 400px;
  max-width: 90vw;

  input {
    border: 2px solid $dark !important;
  }
  button {
    border: 2px solid $dark !important;
    font-weight: bold;

    &:hover {
      transition: ease-out 0.3s;
      background-color: $dark !important;
      color: $light !important;
    }
  }
  .subscribed {
    background-color: rgb(22, 71, 232);
    border-radius: 8px;
    padding: 8px;
    color: white;
  }
}
