@import "../../sass/breakpoints";

.BookShelf {
  padding-top: 30px;
  @include small {
    margin: 0 1em;
  }
  .center {
    text-align: center;
    font-size: 3em;

    @include small {
      font-size: 1.5em;
    }

    @include medium {
      font-size: 3em;
      text-align: center;
    }
  }

  .title {
    margin-top: 20px;
    @include small {
      margin-top: 0px;
    }
  }
  .shelf-title {
    text-align: left;
    padding-bottom: 16px;
  }

  .is-one-quarter {
    text-align: center;
    padding: 5px;
    margin: 6px;

    .image {
      margin: 0 auto;
      width: 13em;
    }

    .margin-cut {
      margin-bottom: 0;
    }

    img-social {
      width: 10px;
      margin: 5px;
    }

    a {
      margin: 4px;
    }
  }

  .column {
    align-items: center;
    max-width: calc(100% - 120px);
    @include small() {
      max-width: calc(100vw - 16px);
    }
  }

  .content {
    margin-top: 25px;
  }

  .enlarge {
    margin-top: 1em;
    font-size: 1.2em;
    @include small() {
      margin-top: 0;
      text-align: left;
      font-size: 1em;
      padding: 8px;
      width: calc(100vw - 1rem);
    }
  }

  button {
    text-align: center;
    margin: 20px 0;
  }

  ul {
    margin-left: 0;

    li {
      margin-bottom: 16px;
      list-style: none;
    }

    h6 {
      margin-bottom: 2px;
    }
  }
  .is-one-quarter {
    text-align: left;
    padding: 5px;

    .image {
      margin: 0 auto;
      width: 13em;
    }

    .margin-cut {
      margin-bottom: 0;
    }

    img-social {
      width: 10px;
      margin: 5px;
    }

    a {
      margin: 4px;
    }

    .menu {
      margin-top: 20px;
      &-label {
        font-size: 1.2em;
        margin-bottom: 5px;
        text-transform: capitalize;
        color: #000;
      }

      .menu-list {
        list-style: none;

        li {
          margin-bottom: 5px;
          a {
            color: #3273dc;
            text-decoration: none;
            transition: color 0.3s ease;

            &:hover {
              color: #555;
            }
          }
          @include small() {
            margin-bottom: 1px;
          }
        }
      }
    }
  }
}
