.ProjectCard {
  .tag {
    border-radius: 4px;
    /*border: 1px solid lightblue;*/
    padding: 0.5rem;
    margin: 1rem 0.2rem 0.5rem 0;
    font-size: 0.8rem;
    /*background-color: aliceblue;*/
    color: black;
    transition: 0.3s;
  }

  .tag:hover {
    cursor: pointer;
    /*box-shadow: inset 0 0 20px lightblue;*/
  }

  .card {
    max-width: 100%;
    min-height: 300px;
    min-width: 250px;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    /* dont use justify content center as single col */
    flex-wrap: wrap;
    flex-grow: 80;
    margin: 0 0.5rem;
  }
}
