@import "../../sass/breakpoints";

.WhoamiPage {
  padding-top: 30px;
  box-sizing: border-box;
  .columns {
    margin-left: 1em;
    @include small() {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .center {
    text-align: center;
    font-size: 4em;

    @include small {
      font-size: 2em;
    }

    @include medium {
      font-size: 3em;
    }
  }

  .title {
    margin-top: 20px;
    padding: 10px;
    @include small() {
      margin-top: 0;
      padding: 0;
    }
  }
  .info-section {
    display: flex;
    height: max-content;
    @include small() {
      flex-direction: column;
      max-width: calc(100vw - 16px);
      align-items: center;
    }
  }
  .columns {
    margin-left: 1em;
    @include small() {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
    }
  }
  .is-one-quarter {
    text-align: center;
    padding: 5px;
    box-sizing: border-box;

    .image {
      margin: 0 auto;
      width: 13em;
      @include small() {
        align-items: center;
        margin: auto;
        width: 10em;
      }
    }
    img-social {
      width: 10px;
      margin: 5px;
    }
    a {
      margin: 4px;
    }
    @include small() {
      height: 100%;
    }
  }
  .column {
    align-items: center;
    max-width: calc(100% - 120px);
    box-sizing: border-box;
    @include small() {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: calc(100% - 16px);
    }
  }

  .content {
    margin-top: 25px;
  }

  .enlarge {
    margin-top: 25px;
    font-size: 1.2em;
    @include small() {
      padding-top: 60px;
      width: 100%;
    }
  }

  button {
    text-align: center;
    margin: 1em 0;
  }

  ul {
    margin-left: 1em;
    li {
      margin-bottom: 16px;
      list-style: none;
    }

    h6 {
      margin-bottom: 2px;
    }
    @include small() {
      margin-left: 2em;
    }
  }
  .wish-day {
    padding: 2em;
  }
}
