@import "../../sass/breakpoints";
.resume {
  display: block;
  align-items: center;
  width: 100%;
  @include small() {
    padding-left: 0.5em;
    max-width: 100%;
  }
  @include medium() {
    padding: 0 3em;
    max-width: 100%;
  }
  @include semi-large() {
    padding: 0 1em;
    max-width: 100%;
  }
  @include very-large() {
    max-width: calc(100% - 100px);
    padding-left: 120px;
  }
}
