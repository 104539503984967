@import "../../sass/breakpoints";
.post {
  @include large() {
    padding: 20px;
  }
  margin: 20px 0 30 px 0;
  background-color: #fff;
  @include small() {
    margin: 0;
    padding: 0;
  }
  .columns {
    @include small() {
      padding: 0;
      width: 100%;
    }
  }
  .sections {
    position: sticky;
    top: 2em;
    @include small() {
      display: none;
    }
    .read-time {
      color: #8e8b82;
    }
    margin-top: 5em;
    width: 24%;
    position: sticky;
    top: 5em;
    @include small() {
      margin-left: 2em;
    }

    &-label {
      font-size: 1.2em;
      margin-bottom: 5px;
      text-transform: capitalize;
      color: #000;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-left: -0.75em;
    }


    li {
      margin-bottom: 5px;
      padding-right: 1em;

      a {
        color: #3273dc;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #363636;
          border-radius: 8px;
        }
      }
      @include small() {
        margin-bottom: 1px;
      }
    }
  }

  .post-content {
    width: 80%;
    padding-left: 2em;
    max-width: calc(100% - 120px);
    font-size: 1.2rem;
    line-height: 1.6;
    @include small() {
      max-width: 100%;
      width: 100%;
      padding-left: 0;
    }
    h1 {
      color: #00171f;
      font-size: 3rem;
      font-weight: 600;
      line-height: 1.125;
      max-width: calc(100% - 16px);
      text-align: left;
      margin: 1em 0;
      @include small() {
        font-size: 2rem;
        max-width: 100%;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;

      .sections {
        width: 100%;
        border-right: none;
        padding-right: 0;
        padding-bottom: 20px;
      }

      .post-content {
        width: 100%;
      }
    }
  }

  &-content {
    font-size: 1.2rem;
    line-height: 1.6;
    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    h2 {
      color: #00171f;
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.125;
      max-width: 80%;
      text-align: left;
      margin: 1em 0;
    }
    h3 {
      color: #00171f;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.125;
      max-width: 80%;
      text-align: left;
      margin: 1em 0;
    }
    ul {
      margin-left: 0;
    }
    blockquote {
      border-left: 4px solid #333;
      padding: 10px 20px;
      margin: 10px 0;
      color: #555;
      font-style: italic;
      background-color: #f5f5f5;
    }

    blockquote p {
      margin: 0;
    }
    /* Basic table styling */
    table {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      font-size: 16px;
      line-height: 1.5;
      word-wrap: break-word;
      border-collapse: collapse;
      display: block;
      width: 100%;
      width: max-content;
      max-width: 100%;
      overflow: auto;
    }
    .img-cap {
      display: ruby;
    }

    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;
      border: 1px solid #c3c3c3;
    }
    /* Table header */
    th {
      background-color: #f8f8f8;
      font-weight: bold;
      text-align: left;
      padding: 10px;
      border: 1px solid #c3c3c3;
    }

    /* Table cells */
    td {
      padding: 6px 13px;
      border: 1px solid #c3c3c3;
      background-color: #fafafa;
    }
    ol {
      counter-reset: item;
      list-style: none;
      margin-left: 1em;
      padding-left: 0;
      li {
        position: relative;
        margin-bottom: 0.5em;
        padding-left: 1.5em;
        line-height: 1.4;
        font-size: medium;
        a {
          word-wrap: break-word;
        }
        &:before {
          content: counter(item) ".";
          font-size: medium;
          counter-increment: item;
          position: absolute;
          left: -1.5em;
          width: 1.5em;
          text-align: right;
          font-weight: bold;
        }
      }
    }
  }

  // Add more styles for images, headings, links, etc. as needed
}
