@import "../../../sass/breakpoints";
.resume-header {
  display: flex;
  justify-content: space-between;
  /* Adjust spacing between columns */
  align-items: flex-start;
  /* Align all content to the top */
  background-color: #fff;
  padding: 1em;
  @include small() {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align content to the left */
    text-align: center;
    @include small() {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2em;
      }

    &:nth-child(2) {
      align-items: flex-start;
      /* Align content to the top for the second column */
    }
    &:nth-child(3) {
      align-items: flex-end;
      text-align: right;
      @include small() {
          display: none;
        }
    }
    a {
      color: #333;
    }
    .first-name {
      color: #820263;
    }
  }

  &__icon {
    @include small() {
      display: flex;
    }
    font-size: 22px;
    margin-bottom: 8px;
    display: flex;
    gap: 0.5em;
  }

  &__link {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;

    a {
      text-decoration: none;
      color: #333;

      &:hover {
        color: #007bff;
      }
    }
    @include small() {
      display: none;
    }
  }

  &__contact {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;

    a {
      text-decoration: none;
      color: #333;

      &:hover {
        color: #007bff;
      }
    }
    @include small() {
      display: none;
    }
  }

  h1 {
    margin: 0;
    font-size: 2.8em;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
    @include small {
      font-size: 2em;
    }
  }
}
