@font-face {
  font-family: "cotham";
  src: local("cotham"), url("../assets/fonts/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "merriweather";

  font-family: "Noto Sans", sans-serif;
  src: local("merriweather"), url("../assets/fonts/NotoSans-Regular.ttf") format("truetype");
}

$serif: "cotham";
$sans: "merriweather";
