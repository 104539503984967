@import "../../sass/breakpoints";

.HomePage {
  padding: 0 12px;
  .is-family-monospace,
  .is-family-code {
    font-family: monospace !important;
    @include small {
      display: none;
    }
  }
  display: flex;
  flex-direction: column;
  @include small() {
    padding: 0;
  }
  .home-section {
    width: 100%;
    margin-top: 32px;
    display: flex;
    padding-bottom: 2em;
    gap: 4em;
    @include small {
      margin-top: 0;
      flex-direction: column;
      gap: 1em;
    }
  }
  #jumbo\ j-img {
    min-width: 50%;
    text-align: left;
    @include small {
      display:none;
    }
    img {
      @include small {
        display: none;
      }
    }
  }
  #jumbo {
    min-width: 50%;
    text-align: left;
    @include small {
      min-width: unset;
      max-width: 100%;
      padding: 0 1em;
    }
    img {
      @include small {
        display: none;
      }
    }

    h1 {
      font-size: 2.5em;

      @include small {
        font-size: 2em;
        margin-top: 1em;
      }

      @include medium {
        font-size: 4em;
      }
    }
    ul {
      list-style: disc outside;
      margin-left: 2em;
      margin-top: 1em;
      line-height: 1.75em;
      li {
        color: #7a7a7a !important;
      }
      li::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px !important;
        text-align: start !important;
        text-align-last: start !important;
      }
      @include small {
        margin-left: 1em;
        margin-top: 0;
      }
    }

    .subtitle {
      font-size: 1em;
      margin-top: 2.5em;
      p {
        margin-top: 0.5em;
      }
      @include small() {
        font-size: 1em;
        margin-top: 0.5em;
      }
    }
    .recent {
      font-weight: 600 !important;
    }
  }
  a {
    color: #22f;
  }
  .linkbar {
    margin-bottom: 20px;

    a {
      margin: 20px;
      color: #22f;
    }
    @include small() {
      display: none;
    }
  }
  .mailbox {
    margin-top: 2em;
    @include small() {
      margin-top: 2em;
    }
  }
}
