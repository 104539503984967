@import "../../sass/breakpoints";
.Navbar {
  margin: 5px 0px;
  border-bottom: 1px solid #aeaeae;
  box-sizing: border-box;
  #brand {
    padding: 3px;
    font-size: 1.8em;
    // text-decoration-line: underline;
    color: #4a4a4a;
    @include small() {
      font-size: 1.5em;
    }
  }

  .navbar-item {
    &:hover {
      background-color: transparent;
    }
  }
}
