@import "../../sass/breakpoints";

.running {
  display: flex;
  width: 100%;
  @include small {
    padding: 0 1em;
    max-width: 100%;
    flex-direction: column;
  }
  .left-side-bar {
    @include small {
      width: calc(100% - 1em);
    }
    .year-selector {
      display: flex;
      justify-content: flex-start;
      margin: 4px 0;
      @include small {
        margin: 0;
      }
    }
    .year-item {
      padding: 4px 8px;
      margin: 0 5px;
      cursor: pointer;
      font-size: 14px;
      color: #333;
      border: 1px solid #ccc;
      border-radius: 5px;
      transition: background-color 0.3s, color 0.3s;
    }

    .year-item.selected {
      background-color: #007bff;
      color: white;
      border-color: #007bff;
    }

    .year-item:hover {
      background-color: #007bff;
    }
    .page-title {
      -webkit-text-size-adjust: 100%;
      tab-size: 4;
      box-sizing: border-box;
      margin: 0;
      margin-top: 2rem;
      margin-bottom: 3rem;
      font-size: 3rem;
      line-height: 1;
      font-weight: 700;
      font-style: italic;
      @include small {
        margin: 1rem 0 2rem 0;
      }
    }
    .page-subtitle {
      font-style: oblique;
      margin-top: 2rem;
      @include small {
        margin-top: 1rem;
      }
    }
    .right-side-pain {
      display: flex;
      flex-direction: column;
      width: max-content;
      .runtable {
        margin-top: 5em;
        width: 100%;
      }
    }
    width: 33%;
    display: flex;
    flex-direction: column;
  }
}
