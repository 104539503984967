@import "../../sass/breakpoints";
.post {
  box-sizing: border-box;
  padding: 20px;
  margin: 20px;
  background-color: #fff;
  @include small() {
    margin: 0;
    padding: 1em;
  }
}
