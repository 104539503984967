@import "../../sass/breakpoints";

.CodePage {
  padding-top: 30px;
  .center {
    text-align: center;
    margin: 3%;
    font-size: 4em;

    @include small {
      font-size: 2em;
      margin: 3%;
    }

    @include medium {
      font-size: 3em;
      margin: 3%;
    }
  }

  .subtitle {
    margin: 6%;
    font-size: 1.5em;
  }
  .description {
    max-width: calc(100% - 240px);
    @include small() {
      max-width: calc(100% - 16px);
      padding: 2em 1em;
      font-size: 1.1em;
    }
  }
}
