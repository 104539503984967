@mixin small {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin medium {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}
@mixin semi-large {
  @media (min-width: 768px) and (max-width: 1044px) {
    @content;
  }
}

@mixin large {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin very-large {
  @media (min-width: 1240px) {
    @content;
  }
}
