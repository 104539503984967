@media only screen and (max-width: 700px) {
  /* Hide the fourth column on small screens */
  .runTable th:nth-child(4),
  .runTable td:nth-child(4) {
    display: none;
  }
  .runRow td {
    padding: 0.36rem !important;
  }
}

.runTable {
  width: 100%;
  font-variant: tabular-nums;
  margin: 2rem 0 5rem 0;
}

.runTable thead {
  color: rgb(204, 204, 204);
  text-align: right;
  cursor: pointer;
}

.runTable thead tr th {
  cursor: pointer;
}

.runTable th {
  padding: 0.5rem;
}

.runRow {
  cursor: pointer;
}

.runRow td {
  padding: 0.5rem;
  border: 0;
  text-align: right;
}

.runRow td:first-child {
  text-align: left;
}

.runRow:hover {
  background-color: lightgray;
}

.selected {
  color: red;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
}

.runDate {
  color: rgb(204, 204, 204);
}
