@import "../../../sass/breakpoints";

.resume-items {
  .experience-group {
    margin-bottom: 1.5em;

    .experience-group__title {
      font-size: 20px;
      font-weight: bold;
      color: #820263;
      margin-bottom: 10px;
      padding-bottom: 0.1em;
      border-bottom: 2px solid #666;
      @include small() {
        font-size: 1.4em;
      }
    }

    .resume-item {
      margin-bottom: 0.25em;
      padding-bottom: 16px;
      @include small() {
        padding-bottom: 8px;
      }
      .resume-item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .resume-item-date h6 {
          font-size: 14px;
          font-weight: bold;
          flex-shrink: 0;
          @include small() {
            display: none;
          }
        }

        .resume-item-title {
          flex-grow: 1;
          text-align: left;
          margin-left: 10px;
          

          .resume-item-company {
            font-size: 18px;
            font-weight: bold;
            @include small() {
                font-size: 1em;
              }
          }

          .resume-item-role {
            font-size: 16px;
            font-weight: bold;
            color: #666;
            @include small() {
                font-size: 0.8em;
              }
          }
        }
      }

      .resume-item-body {
        max-width: calc(100% - 120px);
        font-size: 14px;
        color: #333;
        @include small() {
          max-width: 100%;
        }

        .resume-item-description {
          list-style: disc;
          margin-left: 20px;
          margin-top: 10px;
          @include small() {
            margin-left: 0;
            padding-left: 16px;
          }
          li {
            margin-bottom: 5px;
          }
        }

        ul.resume-item-description {
          list-style-type: none;
        }

        ul.resume-item-description > li {
          text-indent: -5px;
        }

        ul.resume-item-description > li:before {
          content: "- ";
          text-indent: -5px;
        }
      }
    }
  }
}
