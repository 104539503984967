@import "../../sass/breakpoints";

.TimelinePage {
  padding-top: 50px;

  .center {
    text-align: center;
    font-size: 4em;

    @include small {
      font-size: 2em;
    }

    @include medium {
      font-size: 3em;
    }
  }

  .subtitle {
    margin: 10% auto 2px auto;
    font-size: 1.5em;
    @include small {
      padding-top: 1em;
      font-size: 1.2em;
    }
  }
}
