@import "sass/reset";
@import "sass/breakpoints";
@import "sass/colors";
@import "sass/fonts";
@import "sass/bulma";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "../node_modules/bulma/bulma.sass";

body {
  padding: 15px 10%;

  @include small {
    padding: 4px;
  }

  @include medium {
    padding: 4px;
  }

  .serif {
    font-family: $serif;
  }
}
.App {
  display: flex;
  flex-direction: column;
  min-height:calc(100vh - 50px);
   .switch {
    height: calc(100vh - 20px);

    @include small() {
      height: calc(100vh - 20px);
    }
  }
  .math {
    font-size: 20px;
  }

  footer {
    bottom: 0;
    margin-top: auto;
    padding: 28px 0;
    text-align: center;
    width: 100%;
  }
}
