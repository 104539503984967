@import "../../sass/breakpoints";

.BlogPage {
  padding: 30px 0;
  box-sizing: border-box;
  @include small {
    margin: 0 1rem;
  }

  .center {
    text-align: center;
    font-size: 3em;

    @include small {
      font-size: 1.5em;
    }

    @include medium {
      font-size: 3em;
      text-align: center;
    }
  }

  .title {
    margin-top: 20px;

    @include small {
      margin-top: 0px;
      text-align: left;
    }
  }

  .shelf-title {
    text-align: left;
    padding-bottom: 16px;
  }

  .is-one-quarter {
    text-align: center;
    padding: 5px;
    a {
      margin: 4px;
    }
  }
  .columns {
    .is-one-quarter {
      max-width: 20%;
      margin-left: 6px;
      @include small() {
        max-width: 100%;
        margin-left: 0;
      }
    }
  }

  .column {
    align-items: center;
    max-width: calc(100% - 2rem);

    @include small() {
      max-width: calc(100vw - 2rem);
    }
  }

  .content {
    margin-top: 25px;
  }

  .enlarge {
    margin-top: 1em;
    font-size: 1.2em;

    @include small() {
      margin-top: 0;
      text-align: left;
      font-size: 1em;
      padding: 8px;
      width: calc(100vw - 16px);
    }
  }

  button {
    text-align: center;
    margin: 20px 0;
  }

  ul {
    margin-left: -2px;

    li {
      margin-bottom: 16px;
      list-style: none;
      p {
        @include small() {
          padding: 0.25em 0.5em;
        }
      }
    }

    h6 {
      margin-bottom: 2px;
      .link-title {
        color: #3273dc;
        cursor: pointer;
        margin: 4px;

        @include small() {
          margin: 0.5rem;
        }
      }
    }
  }

  .is-one-quarter {
    text-align: left;
    padding: 5px;

    .image {
      margin: 0 auto;
      width: 13em;
    }

    .margin-cut {
      margin-bottom: 0;
    }

    img-social {
      width: 10px;
      margin: 5px;
    }

    a {
      margin: 4px;
    }

    .menu {
      position: sticky;
      top: 2em;
      margin-top: 20px;

      @include small() {
        margin-left: 0.5rem;
      }

      &-label {
        font-size: 1.2em;
        margin-bottom: 5px;
        text-transform: capitalize;
        color: #000;
      }

      .menu-list {
        list-style: none;

        li {
          margin-bottom: 5px;
          @include small() {
            padding: 0;
          }

          a {
            color: #3273dc;
            text-decoration: none;
            transition: color 0.3s ease;
            @include small() {
              padding: 0.5em 0.25em;
            }
            &:hover {
              color: #555;
              border-radius: 6px;
            }
          }

          @include small() {
            margin-bottom: 1px;
          }
        }
      }
    }
  }
}
