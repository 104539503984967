@import "../../sass/breakpoints";

.Stat {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.2em;
  .values {
    font-size: 2rem;
    font-style: italic;
    font-weight: 800;
  }
  .values-m {
      font-size: 1.4rem;
      font-style: italic;
      font-weight: 800;
    }
  .legends {
    font-size: 1rem;
    font-style: italic;
    font-weight: 800;
  }
  .values-s {
      font-size: 1rem;
      font-style: italic;
      font-weight: 800;
    }
}
