@import "../../sass/breakpoints";
.Footer {
  width: 100%;
  margin-top: 50px;
  padding: 3em 2em;
  text-align: center;

  img {
    width: 20px;
    margin: 10px;
  }

  a {
    display: inline;
    color: gray;
  }
  .footer-subtitle {
    font-size: 1rem;
    @include small() {
      font-size: 0.8em;
    }
  }
}
